.About {
  text-align: left;
  margin: 80px;
}
.About-logo {
  height: 10vmin;
  pointer-events: none;
}

.About-image {
  width: 400px;
  margin: 35px;
}

.About-list {
  width: 800px;
}